<template>
  <!-- wallets-funding/资金账户 -->
  <div class="deposit-coin-list-wrap">
    <div class="font-weight400 main-text-3 font12">
      {{$t('coinList.coinList')}}
    </div>
    <ul class="coin-list margin-top12">
      <li v-for="(item, index) in showCoinList"  @click="clickCoin(item)" class="ub padding-bottom4 padding-top4 margin-top16" :key="index">
        <div class="icon-box"></div>
        <div class="label-box margin-left8 font14 main-text-1">
          {{ item.label }}
        </div>
        <div class="name-box margin-left4 font12 main-text-3">
          {{ item.name }}
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from 'vue'
import { List } from 'vant'
Vue.use(List)

export default {
  components: { },
  computed: {
    showCoinList () {
      let list = []
      console.log(this.searchValue)
      if (this.searchValue) {
        list = this.coinList.filter((item) => {
          return item.name.indexOf(this.searchValue) > -1
        })
      } else {
        list = this.coinList
      }
      return list
    }
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      coinList: [
        {
          icon: '',
          label: 'USDT',
          name: 'TetherUS'
        }
      ]
    }
  },
  methods: {
    clickCoin (item) {
      this.$emit('callback', item)
    },
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.deposit-coin-list-wrap{
  .coin-list{
    li{
      align-items: center;
      justify-content: flex-start;
      .icon-box{
        width: 40px;
        height: 40px;
        background-color: #1C1B1E;
        opacity: 0.3;
        border-radius: 50%;
      }
    }
  }
}
</style>
