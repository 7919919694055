<template>
  <!-- wallets-funding/资金账户 -->
  <div class="deposit-wrap">
    <DepositCpt></DepositCpt>
  </div>
</template>
<script>
import DepositCpt from '@/components/deposit/index.vue'

export default {
  name: 'Deposit',
  components: { DepositCpt },
  computed: {
  },
  methods: {
  }
}
</script>
