<template>
  <div class="crypto-wrap">
    <div v-show="!searchValue">
      <div class="history-card">
        <div class="card-header font-weight400 main-text-3 ub ub-btw font12">
          <div>
            {{ $t('coinList.searchHistory') }}
          </div>
          <div @click="clearSearchHistory">
            <img src="@/assets/images/walltes/delete.svg" alt="">
          </div>
        </div>
        <ul class="history-list ub ub-wrap margin-top8" v-if="historyList.length">
          <li v-for="(item, index) in historyList" @click="clickCoin(item)" :key="index" class="margin-right20 background-color-2 main-text-2 margin-bottom20">
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="trending-card">
        <div class="card-header font-weight400 main-text-3 ub ub-btw font12 padding-top20">
          <div>
            {{ $t('coinList.trending') }}
          </div>
        </div>
        <ul class="trending-list ub ub-wrap margin-top8">
          <li v-for="(item, index) in trendingList" @click="clickCoin(item)" :key="index" class="margin-right20 background-color-2 main-text-2 margin-bottom20">
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
    <CoinList @callback="coinListCallback" :searchValue="searchValue"></CoinList>
  </div>
</template>
<script>
import Vue from 'vue'
import { Search, Tab, Tabs, Icon } from 'vant'
import CoinList from '@/components/deposit/coinList/index.vue'
Vue.use(Tab).use(Tabs)
Vue.use(Search)
Vue.use(Icon)

export default {
  name: 'Crypto',
  components: { CoinList },
  props: {
    searchValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      historyList: [
        {
          icon: '',
          label: 'USDT',
          name: 'TetherUS'
        }
      ],
      trendingList: [
        {
          icon: '',
          label: 'USDT',
          name: 'TetherUS'
        }
      ]
    }
  },
  methods: {
    clearSearchHistory () {
      this.historyList = []
    },
    back () {
      this.$router.back()
    },
    coinListCallback (item) {
      console.log(item)
      this.$router.push({
        path: '/depositInfo',
        query: {
          coinName: item.name,
          label: item.label
        }
      })
    },
    clickCoin (item) {
      this.$router.push({
        path: '/depositInfo',
        query: {
          coinName: item.name,
          label: item.label
        }
      })
    }
  }
}
</script>
<style lang="less">
.crypto-wrap{
  box-sizing: border-box;
}
.history-card,.trending-card{
  .history-list,.trending-list{
    padding-top: 0;
    li{
      padding: 0.66667vw;
      border-radius: 0.66667vw;
    }
  }
}
</style>
