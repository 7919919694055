<template>
  <!-- coinList: {
    crypto: '数字货币',
    trending: '热门',
    coinList: '币种列表',
    searchHistory: '历史搜索'
  } -->
  <div class="background-color-4 main-text-1">
    <van-nav-bar :title="title" left-arrow @click-left="back" :border="false">
      <div slot='left'>
        <img src="@/assets/images/system/left-arrow.svg"/>
      </div>
      <div slot='title'>
        {{ $t('deposit.deposit') }}
      </div>
    </van-nav-bar>
    <div class="search-box">
      <!-- show-action -->
      <van-search shape="round" :placeholder="$t('coinList.placeholder')" v-model="value">
      </van-search>
        <!-- <div slot="action"  @click="back">{{$t('cancel')}}</div> -->
    </div>
    <!-- <van-tabs v-model="active">
      <van-tab v-for="(item,index) in tabList" :key="index" :title="item.title">
        <div class="content-view">
          <components :searchValue="value" :is="item.component"></components>
        </div>
      </van-tab>
    </van-tabs> -->
    <div class="content-view">
      <Crypto :searchValue="value"></Crypto>
    </div>
  </div>

</template>
<script>
import Crypto from '@/components/deposit/crypto/index.vue'
// import Cash from '@/components/deposit/cash/index.vue'
import Vue from 'vue'
import { Search, Tab, Tabs } from 'vant'
Vue.use(Tab).use(Tabs)
Vue.use(Search)
export default {
  components: { Crypto },
  computed: {
  },
  data () {
    return {
      title: '',
      active: 0,
      value: '',
      tabList: [
        {
          title: this.$t('coinList.crypto'),
          component: 'Crypto'
        },
        {
          title: this.$t('coinList.cash'),
          component: 'Cash'
        }
      ]
    }
  },
  methods: {
    back () {
      this.$router.back()
    }
  }
}
</script>
<style lang="less">
.search-box{
  box-sizing: border-box;
  height: 13.33333vw;
}
.content-view{
  height: calc(100vh - 36.4vw);
  padding: 30px;
  overflow: scroll;
  box-sizing: border-box;
}
</style>
